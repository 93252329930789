import { useMemo } from 'react';
import { SourceCostReportObject, SourceCostReportObjects } from '../../types';

export function useReportObjectsOptions(
  reportObjects?: SourceCostReportObjects
) {
  return useMemo(() => {
    if (!reportObjects) {
      return {
        experiments: [],
        datasets: []
      };
    }

    return {
      experiments: reportObjects.experiments.map((e) => createOption(e)),
      datasets: reportObjects.datasets.map((e) => createOption(e))
    };
  }, [reportObjects]);
}

const createOption = ({ name, uuid }: SourceCostReportObject) => ({
  value: uuid,
  label: name
});
