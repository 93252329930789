import { Flex, Text, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useCreatePanelStore } from 'store';

interface SuccessMessageProps {
  isNew: boolean;
}

export const SuccessMessage: FC<SuccessMessageProps> = ({ isNew }) => {
  const sequenceName = useCreatePanelStore(
    (state) => state.addedExogenousSequence
  );
  const resetAddedExogenousSequence = useCreatePanelStore(
    (state) => state.resetAddedExogenousSequence
  );

  return (
    <Flex flexDir="column" justify="space-between" align="center" w="100%">
      <Text pb="32px" w="100%">
        The sequence{' '}
        <b>
          <i>{sequenceName}</i>
        </b>{' '}
        was sent to the panel.
        {isNew && (
          <>
            <br />
            <br />
            Adding exogenous sequence to the panel might take some time. The
            process will be running in the background you may continue editing
          </>
        )}
      </Text>
      <Button
        border="1px solid"
        borderColor="border.secondary"
        variant="primaryGhost"
        onClick={resetAddedExogenousSequence}
        data-testid="okButton"
      >
        OK
      </Button>
    </Flex>
  );
};
