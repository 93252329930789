import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import {
  ColumnInstance,
  FilterValue,
  IdType,
  TableInstance
} from 'react-table';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { SearchField } from '../../../../../Fields';
import { useDebounce } from '../../../../../../hooks';
import { Scrollbar } from '../../../../../Scrollbar';
import {
  TriggerButton,
  NotFound,
  SelectAll,
  ColumnList,
  Header
} from './components';
import { positionValues } from 'react-custom-scrollbars';
import { TableColumnsLabels } from '../../../../../../types';

interface ManageTableColumnsProps<T extends {}> {
  tableInstance: TableInstance<T>;
  labels: TableColumnsLabels;
  hiddenColumns: string[];
  disabledColumns: string[];
}

export type ColumnFilter = <T>(
  columnId: IdType<T>,
  updater: FilterValue
) => void;

const ROW_HEIGHT = 40;
export const ManageTableColumns = <T,>({
  tableInstance,
  labels,
  hiddenColumns,
  disabledColumns
}: PropsWithChildren<ManageTableColumnsProps<T>>) => {
  const {
    allColumns,
    setColumnOrder,
    getToggleHideAllColumnsProps,
    setFilter
  } = tableInstance;
  const [searchValue, setSearchValue] = useState('');
  const [filteredColumns, setFilteredColumns] = useState(allColumns);
  const [hiddenColumnIds, setHiddenColumnIds] = useState<string[]>([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const { value } = useDebounce(searchValue, 100);
  const {
    isOpen: isPopoverOpen,
    onClose: onPopoverClose,
    onOpen: onPopoverOpen
  } = useDisclosure();

  const { initialHiddenColumns, listHeight } = useMemo(() => {
    const initialHiddenColumns = ['selection', 'menu', ...hiddenColumns];
    const columnsAmount = allColumns.filter(
      (column) => !initialHiddenColumns.includes(column.id)
    ).length;
    const listHeight = columnsAmount > 5 ? 200 : columnsAmount * ROW_HEIGHT;
    return { initialHiddenColumns, listHeight };
  }, [allColumns, hiddenColumns]);

  useEffect(() => {
    const filteredColumns: ColumnInstance<any>[] = [];
    const hiddenColumnIds: string[] = [];
    allColumns
      .filter((column) => !initialHiddenColumns.includes(column.id))
      .forEach((column) => {
        const { id, Header } = column;
        const header = typeof Header === 'string' ? Header : null;
        const label = labels[id]?.value ?? header ?? id;
        if (label.toLowerCase().includes(value.toLowerCase())) {
          filteredColumns.push(column);
        } else {
          hiddenColumnIds.push(id);
        }
      });
    setFilteredColumns(filteredColumns);
    setHiddenColumnIds(hiddenColumnIds);
  }, [allColumns, initialHiddenColumns, labels, value]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index !== source.index) {
      const columns = allColumns.map((column) => column.id);
      const [removed] = columns.splice(source.index, 1);
      if (!disabledColumns.includes(columns[destination.index])) {
        columns.splice(destination.index, 0, removed);
        setColumnOrder(columns);
      }
    }
  };

  const onScroll = (values: positionValues) => {
    setIsScrolled(values.top > 0);
  };

  const disabledColumnsIds = [
    ...disabledColumns,
    ...hiddenColumnIds,
    ...initialHiddenColumns
  ];

  return (
    <Popover
      isLazy
      placement="auto"
      isOpen={isPopoverOpen}
      onClose={onPopoverClose}
      offset={[3, 5]}
    >
      <TriggerButton
        isPopoverOpen={isPopoverOpen}
        onPopoverOpen={onPopoverOpen}
      />
      {isPopoverOpen && (
        <Portal>
          <PopoverContent
            border="1px solid"
            borderColor="border.normal"
            boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
            w="327px"
            overflow="hidden"
          >
            <Header onPopoverClose={onPopoverClose} />
            <PopoverBody p="0" mt="8px" h="100%">
              <VStack spacing="16px" w="100%" h="100%" align="start">
                <Box p="0 24px" w="100%">
                  <SearchField
                    name="columnSearch"
                    placeholder="Search"
                    controlProps={{
                      w: '100%'
                    }}
                    value={searchValue}
                    isRightElementShown={!!searchValue}
                    onChange={(e) => setSearchValue(e.target.value || '')}
                    rightElementProps={{
                      onClick: () => setSearchValue('')
                    }}
                  />
                </Box>
                {filteredColumns.length ? (
                  <VStack spacing="0" w="100%" h="100%" align="start">
                    <SelectAll
                      columns={allColumns}
                      isScrolled={isScrolled}
                      disabledColumns={disabledColumnsIds}
                      getToggleHideAllColumnsProps={
                        getToggleHideAllColumnsProps
                      }
                    />
                    <Scrollbar autoHeight onScrollFrame={onScroll}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <ColumnList
                          columns={allColumns}
                          labels={labels}
                          hiddenColumns={[
                            ...initialHiddenColumns,
                            ...hiddenColumnIds
                          ]}
                          disabledColumns={disabledColumnsIds}
                          setFilter={setFilter}
                        />
                      </DragDropContext>
                    </Scrollbar>
                  </VStack>
                ) : (
                  <Box h={listHeight + ROW_HEIGHT + 'px'} w="100%">
                    <NotFound />
                  </Box>
                )}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};
