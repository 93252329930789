import { useState, FC } from 'react';
import { ExogenousSequenceForm } from 'components';
import {
  SuccessMessage,
  SavedExogenousSequences,
  ErrorMessage
} from './components';
import { RadioGroup, HStack, Radio, VStack, Box } from '@chakra-ui/react';
import { useCreatePanelStore } from 'store';
import { useCalculatingTranscriptsPolling } from './useCalculatingTranscriptsPolling';
import { SingleAccordion } from '@vizgen/vizgen-ui';
import { useParams } from 'react-router';
import { CreateExogenousSequenceInput } from 'generated/types';
import { shallow } from 'zustand/shallow';

export const ExogenousSequences: FC = () => {
  const { panelId } = useParams();
  const [
    isSequenceUpdating,
    addedExogenousSequence,
    createExogenousSequence,
    addExogenousSequenceToPanel
  ] = useCreatePanelStore(
    (state) => [
      state.isSequenceUpdating,
      state.addedExogenousSequence,
      state.createExogenousSequence,
      state.addExogenousSequenceToPanel
    ],
    shallow
  );
  const [type, setType] = useState('new');
  const isNew = type === 'new';

  useCalculatingTranscriptsPolling();

  const onSubmit = async (data: CreateExogenousSequenceInput) => {
    const res = await createExogenousSequence(data);
    if (res) {
      await addExogenousSequenceToPanel(res.name, res.id, panelId!);
    }
  };

  return (
    <SingleAccordion title="Exogenous Sequences">
      <VStack spacing="20px" align="start" w="100%">
        {addedExogenousSequence ? null : (
          <VStack spacing="16px" align="start" w="100%">
            <ErrorMessage />
            <RadioGroup defaultValue={type} onChange={setType} ml="8px">
              <HStack spacing="24px">
                <Radio value="new" name="new" disabled={isSequenceUpdating}>
                  New one
                </Radio>
                <Radio value="saved" name="saved" disabled={isSequenceUpdating}>
                  Add from saved
                </Radio>
              </HStack>
            </RadioGroup>
          </VStack>
        )}
        <Box w="100%">
          {addedExogenousSequence ? (
            <SuccessMessage isNew={isNew} />
          ) : isNew ? (
            <ExogenousSequenceForm onSubmit={onSubmit} />
          ) : (
            <SavedExogenousSequences />
          )}
        </Box>
      </VStack>
    </SingleAccordion>
  );
};
