import {
  PanelTranscriptSource,
  PanelTranscriptStatus,
  PanelTranscriptType
} from 'generated/types';
import { CreatePanelState } from './models';
import { isIssue, getTotalAbundance } from './utils';
import {
  MAX_MERFISH_GENES_AMOUNT,
  MAX_MERFISH_GENE_ABUNDANCE,
  MAX_TOTAL_ABUNDANCE
} from 'consts';
import { TranscriptModel } from 'store';

const MAX_SAVE_EXOGENOUS_SEQUENCES_AMOUNT = 1;

export const transcriptsByStatusSelector =
  (status: PanelTranscriptStatus) => (state: CreatePanelState) => {
    if (status === PanelTranscriptStatus.insufficient_tr) {
      return state.transcripts.filter(
        (transcript) => transcript.status === status && !transcript.isIncluded
      );
    }
    return state.transcripts.filter(
      (transcript) => transcript.status === status
    );
  };

export const transcriptsByStatusAmountSelector =
  (status: PanelTranscriptStatus) => (state: CreatePanelState) => {
    return transcriptsByStatusSelector(status)(state).length;
  };

export const issuesAmountSelector = (state: CreatePanelState) => {
  return (
    state.transcripts.reduce((accumulator, transcript) => {
      return (
        accumulator +
        (isIssue(transcript.status, transcript.isIncluded) ? 1 : 0)
      );
    }, 0) +
    duplicatedGeneNamesSelector(state).length +
    Number(
      transcriptsByTypeSelector(PanelTranscriptType.primary)(state).length >
        MAX_MERFISH_GENES_AMOUNT
    )
  );
};

export const transcriptsByTypeSelector =
  (type: PanelTranscriptType) => (state: CreatePanelState) => {
    return state.transcripts.filter((transcript) => transcript.type === type);
  };

export const transcriptByIdSelector =
  (id: string) => (state: CreatePanelState) => {
    return state.transcripts.find(
      (transcript) => transcript.transcriptId === id
    );
  };

export const transcriptsByTypeAmountSelector =
  (type: PanelTranscriptType) => (state: CreatePanelState) => {
    return transcriptsByTypeSelector(type)(state).length;
  };

export const isPanelCompletedSelector = (state: CreatePanelState) => {
  return state.panel?.isCompleted || false;
};

export const totalPrimaryAbundanceSelector = (state: CreatePanelState) => {
  const primaryTranscripts = transcriptsByTypeSelector(
    PanelTranscriptType.primary
  )(state);
  return +getTotalAbundance(primaryTranscripts).toFixed(2);
};

export const auxProbesSelector = (state: CreatePanelState) => {
  const transcripts = transcriptsByTypeSelector(PanelTranscriptType.sequential)(
    state
  );
  return transcripts
    .filter((transcript) => transcript.auxProbes)
    .map((transcript) => transcript.auxProbes)
    .sort()
    .join(', ');
};

export const merfishThresholdGenesAmountSelector = (
  state: CreatePanelState
) => {
  return state.transcripts.filter(
    (transcript) =>
      transcript.type === PanelTranscriptType.primary &&
      transcript.abundance > MAX_MERFISH_GENE_ABUNDANCE
  ).length;
};

export const isReferenceAbundanceExceededSelector = (
  state: CreatePanelState
) => {
  const primaryTranscripts = transcriptsByTypeSelector(
    PanelTranscriptType.primary
  )(state);
  if (getTotalAbundance(primaryTranscripts) > MAX_TOTAL_ABUNDANCE) {
    return false;
  }
  const hasRefAbundance = primaryTranscripts.some(
    ({ refAbundance }) => refAbundance
  );
  if (hasRefAbundance) {
    return (
      primaryTranscripts
        .map(({ refAbundance, abundance }) =>
          refAbundance ? refAbundance : abundance
        )
        .reduce((accum, abundance) => {
          accum += abundance;
          return accum;
        }, 0) > MAX_TOTAL_ABUNDANCE
    );
  }
  return false;
};

export const isTotalAbundanceInvalidSelector = (state: CreatePanelState) => {
  return totalPrimaryAbundanceSelector(state) > MAX_TOTAL_ABUNDANCE;
};

export const isAuxProbeMissingSelector = (state: CreatePanelState) => {
  const sequentialTranscripts = transcriptsByTypeSelector(
    PanelTranscriptType.sequential
  )(state);
  return sequentialTranscripts.some((transcript) => !transcript.auxProbes);
};

export const identifiedTranscriptsSelector = (state: CreatePanelState) => {
  return state.transcripts.filter((t) => !!t.geneName);
};

export const duplicatedGeneNamesSelector = (state: CreatePanelState) => {
  return [
    ...state.transcripts
      .reduce((accum, t) => {
        if (t.geneName) {
          accum.set(t.geneName, [...(accum.get(t.geneName) || []), t]);
        }
        return accum;
      }, new Map())
      .values()
  ]
    .filter((list) => list.length > 1)
    .flat() as TranscriptModel[];
};

export const isExogenousSequencesAmountExceededSelector = (
  state: CreatePanelState
) =>
  state.transcripts.filter((t) => t.source === PanelTranscriptSource.E).length >
  MAX_SAVE_EXOGENOUS_SEQUENCES_AMOUNT;
