import { FC, useEffect, useState } from 'react';
import {
  Stack,
  Flex,
  Box,
  Tabs,
  TabList,
  HStack,
  TabPanelProps,
  TabPanels,
  TabPanel
} from '@chakra-ui/react';
import { GeneListTab, PanelCompositionTab, Topbar } from './components';
import {
  isReferenceAbundanceExceededSelector,
  MessageType,
  transcriptsByTypeAmountSelector,
  useCreatePanelStore,
  useNotificationsStore
} from 'store';
import { Notifications } from 'components';
import { CustomTab } from '@vizgen/vizgen-ui';
import { PanelTranscriptType } from 'generated/types';
import {
  MAX_MERFISH_GENES_AMOUNT,
  STANDARD_MERFISH20_GENES_AMOUNT
} from 'consts';

const tabPanelStyles: TabPanelProps = {
  p: '0',
  h: '100%',
  d: 'flex',
  flexDirection: 'column',
  w: '100%'
};

export const MainContent: FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const isReferenceAbundanceExceeded = useCreatePanelStore((state) =>
    isReferenceAbundanceExceededSelector(state)
  );
  const primaryTranscriptsAmount = useCreatePanelStore(
    transcriptsByTypeAmountSelector(PanelTranscriptType.primary)
  );
  const merfishVersion = useCreatePanelStore(
    (state) => state.panel?.merfishVersion
  );
  const setNotifications = useNotificationsStore(
    (state) => state.setNotifications
  );

  useEffect(() => {
    setNotifications([
      {
        message:
          'Vizgen’s reference abundances are greater than the suggested limit, but user supplied abundances are within our guidances.',
        type: MessageType.ReferenceAbundanceExceeded,
        isVisible: isReferenceAbundanceExceeded
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReferenceAbundanceExceeded]);

  useEffect(() => {
    setNotifications([
      {
        message: `Panels with over 920 genes are constructed in a way that precludes the use of spike-in kits.`,
        type: MessageType.PrecludeUseOfSpikeInKits,
        isVisible:
          merfishVersion === '2' &&
          primaryTranscriptsAmount > STANDARD_MERFISH20_GENES_AMOUNT &&
          primaryTranscriptsAmount <= MAX_MERFISH_GENES_AMOUNT
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryTranscriptsAmount]);

  return (
    <Stack
      pl="23px"
      w="calc(100% - 455px)"
      transition="width 0.3s ease-in-out"
      overflowY="hidden"
      h="100%"
      spacing="0"
    >
      <Notifications>
        <Box minH="68px" />
      </Notifications>
      <Topbar />
      <Tabs
        isLazy
        lazyBehavior="keepMounted"
        variant="unstyled"
        w="100%"
        h="100%"
        bg="background.primary"
        d="flex"
        pt="16px"
        flexDirection="column"
        pos="relative"
        alignItems="center"
        index={tabIndex}
        onChange={setTabIndex}
      >
        <Flex w="100%" justify="center">
          <TabList pos="relative" w="100%" h="48px">
            <HStack spacing="24px">
              <CustomTab h="100%" data-testid="geneListButton">
                Gene List
              </CustomTab>
              <CustomTab h="100%" data-testid="panelCompositionButton">
                Panel Composition
              </CustomTab>
            </HStack>
          </TabList>
        </Flex>
        <TabPanels
          h="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <TabPanel {...tabPanelStyles}>
            <GeneListTab />
          </TabPanel>
          <TabPanel {...tabPanelStyles}>
            <PanelCompositionTab />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};
