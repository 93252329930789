import { FC } from 'react';
import { HStack, VStack, Text, Link } from '@chakra-ui/react';
import { PhoneIcon, MailIcon } from './assets';
import { SUPPORT_EMAIL } from 'consts';

export enum Team {
  Main = 'Main',
  CC = 'Customer Care',
  TS = 'Technical Support'
}

export enum NumberType {
  Toll = 'Toll Free',
  Local = 'Local Number'
}

const PHONE_NUMBERS: Record<Team, Record<NumberType, string>> = {
  [Team.Main]: {
    [NumberType.Toll]: '+1 833 222 8206',
    [NumberType.Local]: '+1 617 798 4406'
  },
  [Team.CC]: {
    [NumberType.Toll]: '+1 833 222 8194',
    [NumberType.Local]: '+1 617 798 4407'
  },
  [Team.TS]: {
    [NumberType.Toll]: '+1 833 229 1939',
    [NumberType.Local]: '+1 617 798 4408'
  }
};

export const ContactInfo: FC = () => {
  return (
    <HStack spacing="24px" alignItems="flex-start" w="100%">
      <HStack
        spacing="24px"
        maxW="530px"
        w="100%"
        pt="6px"
        alignItems="flex-start"
      >
        <VStack spacing="16px" w="100%" alignItems="start">
          <HStack spacing="4px" w="100%">
            <MailIcon />
            <Text color="content.primary" fontWeight="heavy">
              Email
            </Text>
          </HStack>
          <Link href={`mailto:${SUPPORT_EMAIL}`} variant="underline">
            {SUPPORT_EMAIL}
          </Link>
        </VStack>
        <VStack spacing="12px" w="100%" alignItems="start">
          <HStack spacing="4px" w="100%">
            <PhoneIcon />
            <Text color="content.primary" fontWeight="heavy">
              Phone
            </Text>
          </HStack>
          <VStack spacing="16px" w="100%" alignItems="start">
            {Object.entries(PHONE_NUMBERS).map(([team, numbers]) => (
              <VStack key={team} spacing="0" w="100%" alignItems="start">
                <Text fontWeight="heavy">{team}</Text>
                {Object.entries(numbers).map(([type, number]) => (
                  <HStack
                    key={number}
                    spacing="4px"
                    w="100%"
                    justifyContent="space-between"
                  >
                    <Text>{type}: </Text>
                    <Link href={`tel:${number}`}>{number}</Link>
                  </HStack>
                ))}
              </VStack>
            ))}
          </VStack>
        </VStack>
      </HStack>
    </HStack>
  );
};
