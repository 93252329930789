import { Box, Flex, Text } from '@chakra-ui/react';
import { MAX_MERFISH_GENES_AMOUNT } from 'consts';
import { useGenesAmountExceeded } from 'hooks';
import { FC } from 'react';
import { UpIcon } from './assets';

export const MaxGenesRow: FC = () => {
  const isGenesAmountExceeded = useGenesAmountExceeded();

  if (!isGenesAmountExceeded) {
    return null;
  }

  return (
    <Flex
      p="12px 0 12px 16px"
      alignItems="center"
      justify="start"
      css={{
        '&:first-of-type .issues-icon, &:first-of-type .issues-text': {
          paddingTop: '4px'
        },
        '&:last-of-type .issues-main-content': {
          borderBottom: 0
        }
      }}
    >
      <Flex overflow="hidden" minW="24px" className="issues-icon">
        <UpIcon />
      </Flex>
      <Box
        w="calc(100% - 24px)"
        className="issues-main-content"
        borderBottom="1px solid"
        borderColor="border.divider"
        pr="12px"
        ml="10px"
      >
        <Text color="content.additional">
          too many genes added (max {MAX_MERFISH_GENES_AMOUNT} allowed)
        </Text>
      </Box>
    </Flex>
  );
};
