import { HStack, Text } from '@chakra-ui/react';
import { MAX_MERFISH_GENES_AMOUNT } from 'consts';
import { useGenesAmountExceeded } from 'hooks';
import { FC } from 'react';
import { UpIcon } from './assets';

export const MaxGenesIssue: FC = () => {
  const isGenesAmountExceeded = useGenesAmountExceeded();

  if (!isGenesAmountExceeded) {
    return null;
  }

  return (
    <HStack spacing="8px">
      <UpIcon />
      <Text color="content.additional">
        too many genes added (max {MAX_MERFISH_GENES_AMOUNT} allowed)
      </Text>
    </HStack>
  );
};
