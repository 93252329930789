import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  MenuProps,
  useDisclosure,
  MenuListProps,
  MenuButtonProps
} from '@chakra-ui/react';
import { FC } from 'react';
import ThreeDotsIcon from './assets/threeDotsIcon.svg';

interface DotsMenuProps {
  menuProps?: Omit<MenuProps, 'children'>;
  menuListProps?: MenuListProps;
  buttonProps?: MenuButtonProps;
}

export const DotsMenu: FC<DotsMenuProps> = ({
  menuProps,
  menuListProps,
  buttonProps,
  children
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Menu
      offset={[0, 0]}
      onOpen={onOpen}
      onClose={onClose}
      isLazy
      lazyBehavior="unmount"
      autoSelect={false}
      {...menuProps}
    >
      <MenuButton
        data-testid="menuButton"
        d="flex"
        alignItems="center"
        w="100%"
        h="100%"
        justifyContent="center"
        _active={{
          bg: 'button.ghostOnPrimary.pressed',
          '& .three-dots-icon': {
            fill: 'content.secondary'
          }
        }}
        css={{
          '& svg': {
            margin: 'auto'
          }
        }}
        {...buttonProps}
      >
        <Box p="0 4px">
          <ThreeDotsIcon />
        </Box>
      </MenuButton>
      {isOpen && (
        <Portal>
          <MenuList maxW="265px" {...menuListProps}>
            {children}
          </MenuList>
        </Portal>
      )}
    </Menu>
  );
};
