import { Link } from '@chakra-ui/react';
import { Notification, NotificationType } from '@vizgen/vizgen-ui';
import { SUPPORT_EMAIL } from 'consts';
import { FC } from 'react';
import {
  isExogenousSequencesAmountExceededSelector,
  useCreatePanelStore
} from 'store';

export const ErrorMessage: FC = () => {
  const isExogenousSequencesAmountExceeded = useCreatePanelStore(
    isExogenousSequencesAmountExceededSelector
  );

  if (!isExogenousSequencesAmountExceeded) {
    return null;
  }

  return (
    <Notification
      type={NotificationType.Error}
      messages={[
        <>
          Please note, binding sites for exogenous sequences are only screened
          for specificity against the host transcriptome, not against other
          exogenous sequences. If your experimental design involves detecting
          multiple, highly homologous exogenous sequences, contact{' '}
          <Link href={`mailto:${SUPPORT_EMAIL}`} variant="underline">
            Vizgen Support
          </Link>{' '}
          before proceeding.
        </>
      ]}
    />
  );
};
