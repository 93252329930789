import {
  Flex,
  HStack,
  Text,
  Box,
  IconButton,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import CloseIcon from './assets/closeIcon.svg';
import { Icon } from './components';
import { NotificationType } from './types';

interface NotificationProps {
  type: NotificationType;
  messages: Array<ReactNode | string>;
  isCompact?: boolean;
  isCloseAllowed?: boolean;
  onClose?: () => void;
}

export const Notification: FC<NotificationProps> = ({
  type,
  messages,
  isCompact,
  isCloseAllowed,
  onClose
}) => {
  const getColor = () => {
    switch (type) {
      case NotificationType.Error:
        return 'state.error10Percent';
      case NotificationType.Warning:
        return 'state.searchHighlight';
      case NotificationType.Info:
        return 'state.infoLight10Percent';
    }
  };

  if (!messages.length) {
    return null;
  }

  return (
    <Flex
      w="100%"
      borderRadius="24px"
      background={getColor()}
      padding={isCompact ? '4px 16px 4px 12px' : '8px 24px 8px 16px'}
      alignItems="center"
    >
      <HStack spacing={isCompact ? '8px' : '12px'} w="100%">
        <Flex align="center" justify="center" w="32px" h="32px">
          <Icon type={type} size={isCompact ? '24px' : '32px'} />
        </Flex>
        {messages.length === 1 ? (
          <Text as="span" color="content.primary">
            {messages[0]}
          </Text>
        ) : (
          <Box>
            <UnorderedList>
              {messages.map((message, i) => (
                <ListItem key={i}>
                  <Text color="content.primary">{message}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
      </HStack>
      {isCloseAllowed && (
        <IconButton
          icon={<CloseIcon />}
          size="icon"
          ml="8px"
          variant="ghost"
          aria-label="close"
          cursor="pointer"
          onClick={onClose}
        />
      )}
    </Flex>
  );
};
