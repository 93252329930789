import { PanelOwnershipType } from 'generated/types';
import { CreatePanel, UpdatePanel, GetPanel } from 'api';
import {
  PanelTranscriptStatus,
  PanelTranscriptType,
  PanelTranscriptSource,
  CreateExogenousSequenceInput,
  ExogenousSequence,
  PanelTranscriptSequentialAuxProbes,
  GeneSymbolSynonymInput,
  Panel
} from 'generated/types';
import { ReferenceModel, TissueModel } from '../globalLists';
import { DefaultItemType } from '@vizgen/vizgen-ui';

export type CreatePanelModel =
  | CreatePanel['createPanel']
  | UpdatePanel['updatePanel']
  | GetPanel['getPanel'];

export type TranscriptModel = {
  comment: string;
  status: PanelTranscriptStatus;
  abundance: number;
  statusMessage: string;
  geneName: string;
  type: PanelTranscriptType;
  targetRegionQty: number;
  transcriptId: string;
  userSpecifiedName: string;
  isIncluded: boolean;
  source: PanelTranscriptSource;
  geneId?: string;
  auxProbes?: PanelTranscriptSequentialAuxProbes | null;
  synonyms?: Array<GeneSymbolSynonymInput>;
  refAbundance?: number;
};

export type CreatePanelData = {
  tissueId: string;
  panelName: string;
  transcriptomeId: string;
  comment: string;
  isProprietary: boolean;
  ownershipType: PanelOwnershipType;
  merfishVersion: string;
};

export type UpdatePanelData = Omit<
  CreatePanelData,
  'isProprietary' | 'ownershipType'
>;

export type ToggleTranscriptPanelModel = {
  type: PanelTranscriptType;
  transcriptId: string;
};

export type CreatePanelState = {
  searchValue?: string;
  panel: CreatePanelModel | undefined;
  isUploadingGenes: boolean;
  isUpdatingGenes: boolean;
  isFetchingGenes: boolean;
  isFinalizingPanel: boolean;
  isPanelUpdating: boolean;
  transcripts: TranscriptModel[];
  duplicateGenes?: GeneInfo[];
  sourcePanel: Panel | null;
  addedExogenousSequence: string;
  isSequenceUpdating: boolean;
  isSubmitted: boolean;
  isIssuesPanelExpanded: boolean;
  toggleIssuesPanelExpanded: () => void;
  setSearchValue: (value: string) => void;
  resetAddedExogenousSequence: () => void;
  createExogenousSequence: (
    data: CreateExogenousSequenceInput
  ) => Promise<ExogenousSequence | undefined>;
  addExogenousSequenceToPanel: (
    sequenceName: string,
    sequenceId: string,
    panelId: string
  ) => Promise<void>;
  addGenesFromSourcePanel: (
    sourcePanel: Panel | null,
    targetPanelId: string
  ) => Promise<void>;
  setPanel: (panel: CreatePanelModel | undefined) => void;
  setNotFoundErrorMessage: (transcripts: TranscriptModel[]) => void;
  resetNotFoundErrorMessage: () => void;
  fetchGenes: (panelId: string, handleStatus?: boolean) => Promise<void>;
  clearState: () => void;
  uploadTranscripts: (value: string) => Promise<boolean>;
  toggleTranscriptPanel: (transcripts: ToggleTranscriptPanelModel[]) => void;
  changeNote: (transcriptId: string, comment: string) => Promise<void>;
  updateAuxProbe: (
    transcriptId: string,
    auxProbes: PanelTranscriptSequentialAuxProbes
  ) => Promise<void>;
  deleteTranscripts: (transcriptIds: string[]) => Promise<void>;
  finalizePanel: () => Promise<void>;
  validatePanel: () => Promise<boolean>;
  createPanel: (data: PanelFormData) => Promise<string>;
  updatePanel: (data: PanelFormData) => Promise<void>;
  setIsUpdating: () => void;
  setTranscripts: (transcripts: TranscriptModel[]) => void;
  addTranscripts: (transcripts: TranscriptModel[]) => void;
  includeInsufficient: (id: string, excludeMode?: boolean) => Promise<void>;
  includeAllInsufficient: (transcriptIds: string[]) => Promise<void>;
  includeSynonym: (
    geneIdentifier: string,
    transcriptId: string
  ) => Promise<void>;
};

export interface PanelFormData {
  comment?: string | null;
  panelName?: string;
  reference?: ReferenceModel;
  tissue?: TissueModel;
  sourcePanel?: Panel | null;
  isProprietary?: boolean;
  merfishVersion?: DefaultItemType | null;
}

export enum MessageType {
  MerfishGenesAmountExceeded = 'ERROR_MerfishGenesAmountExceeded',
  NoMerfishGenes = 'ERROR_NoMerfishGenes',
  MerfishThresholdExceeded = 'ERROR_MerfishThresholdExceeded',
  TotalAbundanceExceeded = 'ERROR_TotalAbundanceExceeded',
  SequentialGenesAmountExceeded = 'ERROR_SequentialGenesAmountExceeded',
  OtherIssues = 'ERROR_OtherIssues',
  GenesNotFound = 'ERROR_GenesNotFound',
  ProbeMissing = 'ERROR_ProbeMissing',
  UploadGenesAmountExceeded = 'ERROR_UploadGenesAmountExceeded',
  ExogenousSequencesIdentification = 'WARN_ExogenousSequencesIdentification',
  ReferenceAbundanceExceeded = 'WARN_ReferenceAbundanceExceeded',
  UnacceptableChemistryVersion = 'ERROR_UnacceptableChemistryVersion',
  ChemistryVersionRestrictions = 'INFO_ChemistryVersionRestrictions',
  PrecludeUseOfSpikeInKits = 'WARN_PrecludeUseOfSpikeInKits'
}

export type GeneInfo = {
  geneInput: string;
  name: string;
};
