import {
  Flex,
  FlexProps,
  HStack,
  IconButton,
  TextProps,
  useClipboard,
  useDisclosure
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import CopyIcon from './assets/copyIcon.svg';
import { theme } from '../../theme';
import { TooltipText } from '../TooltipText';
const { colors } = theme;

interface ClipboardCellProps {
  value?: string;
  clipboardValue?: string;
  rightItem?: ReactNode;
  leftItem?: ReactNode;
  tooltipLabel?: ReactNode | string;
  showTooltip?: boolean;
  stylingProps?: {
    container?: FlexProps;
    text?: TextProps;
  };
}

export const ClipboardCell: FC<ClipboardCellProps> = ({
  value,
  clipboardValue,
  children,
  rightItem,
  leftItem,
  tooltipLabel,
  showTooltip,
  stylingProps
}) => {
  const clipboardData = clipboardValue || value || '';
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { onCopy } = useClipboard(clipboardData);

  return (
    <Flex w="100%" {...stylingProps?.container}>
      {leftItem && <Flex p="12px 0px 12px 12px">{leftItem}</Flex>}
      <HStack
        w="100%"
        py="12px"
        pl={leftItem ? '4px' : '12px'}
        pr={rightItem ? '8px' : '12px'}
        spacing="8px"
        isTruncated
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        {children ? (
          children
        ) : (
          <TooltipText
            textProps={{
              ...stylingProps?.text
            }}
            tooltipLabel={tooltipLabel}
            showTooltip={showTooltip}
          >
            {value}
          </TooltipText>
        )}
        {isOpen && clipboardData && (
          <IconButton
            data-testid="copyButton"
            aria-label="Copy to clipboard"
            variant="primaryGhost"
            size="auto"
            _hover={{
              path: {
                stroke: colors.content.link.normal
              }
            }}
            icon={<CopyIcon />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCopy();
            }}
          />
        )}
      </HStack>
      {rightItem && <Flex p="12px 12px 12px 0">{rightItem}</Flex>}
    </Flex>
  );
};
