import { MAX_MERFISH_GENES_AMOUNT } from 'consts';
import { PanelTranscriptType } from 'generated/types';
import { transcriptsByTypeSelector, useCreatePanelStore } from 'store';

export function useGenesAmountExceeded() {
  const primaryTranscripts = useCreatePanelStore(
    transcriptsByTypeSelector(PanelTranscriptType.primary)
  ).length;

  return primaryTranscripts > MAX_MERFISH_GENES_AMOUNT;
}
