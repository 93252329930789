import {
  Box,
  Flex,
  HStack,
  StackDivider,
  StackProps,
  TabList,
  TabPanel,
  TabPanelProps,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react';
import { BarChart } from '../BarChart';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  ChartParameters,
  ReportParameters,
  ReportStatus,
  ReportSummary,
  ReportTable
} from './components';
import {
  DateRangeType,
  Dimension,
  ReportDateRange,
  ReportParametersModel,
  SourceCostReportModel,
  SourceCostReportObjects
} from './types';
import {
  dateRangeTypeOptions,
  reportObjectOptions,
  horizontalTableLabel,
  dimensionLabels
} from './consts';
import { useResizeDetector } from 'react-resize-detector';
import {
  renderDetailedGroupedTooltip,
  renderDetailedTooltip,
  renderSummaryGroupedTooltip,
  renderSummaryTooltip
} from './tooltips';
import { useSortAvailableReportObjects } from './useSortReportObjects';
import { useDetailedReportData } from './useDetailedReportData';
import { useSummaryReportData } from './useSummaryReportData';
import { useBarColors } from './useBarColors';
import { useSortReport } from './useSortReport';
import { CustomTab } from '../CustomTab';
import { Tab } from '../Tab';
import { useChartLabels } from './useChartLabels';
import {
  useTransformDetailedDatasets,
  useTransformSummaryDatasets
} from './transformChartDatasets';
import { getBarParams, getDateRange } from './utils';
import { useTopResourceIntensiveObjects } from './useTopResourceIntensiveObjects';
import { useReportObjects } from './useReportObjects';
import { useReportTotalSize } from './useReportTotalSize';
import { useTotalReportEntities } from './useTotalReportEntities';
import { useTopResourceIntensiveOrganizations } from './useTopResourceIntensiveOrganizations';
import { useReportOrganizations } from './useReportOrganizations';

interface CostTrackingProps {
  detailedReport?: SourceCostReportModel[];
  totalReport?: SourceCostReportModel[];
  availableReportObjects?: SourceCostReportObjects;
  showOrganizationDetails?: boolean;
  organizationId: string;
  noOptionsLabel: string;
  isLoading: boolean;
  isError: boolean;
  onParametersUpdate: (dateRange: ReportDateRange) => void;
}

const tabPanelStyles: TabPanelProps = {
  p: '0',
  h: '100%',
  w: '100%',
  d: 'flex',
  flexDir: 'column',
  overflow: 'hidden'
};
const initialState: ReportParametersModel = {
  isGroupedByOrganization: false,
  isStacked: false,
  dateRangeType: dateRangeTypeOptions[0],
  dateRange: getDateRange(dateRangeTypeOptions[0].value),
  reportObject: reportObjectOptions[0],
  selectedReportObjects: []
};
const summaryContainerProps: StackProps = {
  p: '72px 12px 0 4px',
  bg: 'background.highlight',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: 'border.divider'
};
const summaryLabels = [''];
const getSummaryBarParams = () => ({
  barThickness: 24,
  borderWidth: 0,
  barWidth: 24,
  offset: 0,
  spacing: 40
});

export const CostTracking: FC<CostTrackingProps> = ({
  detailedReport,
  totalReport,
  availableReportObjects,
  showOrganizationDetails = false,
  organizationId,
  noOptionsLabel,
  isLoading,
  isError,
  children,
  onParametersUpdate
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const isEgress = tabIndex === 0;
  const { ref: containerRef, width: containerWidth } = useResizeDetector();
  const { ref: smallChartRef, width: smallChartWidth } = useResizeDetector();
  const [parameters, setParameters] = useState(initialState);
  const sortedAvailableReportObjects = useSortAvailableReportObjects(
    availableReportObjects
  );
  const sortedDetailedReport = useSortReport(detailedReport);
  const sortedTotalReport = useSortReport(totalReport);
  const reportObjects = useReportObjects(
    parameters,
    sortedAvailableReportObjects
  );
  const reportOrganizations = useReportOrganizations(
    reportObjects,
    sortedDetailedReport
  );
  const topObjects = useTopResourceIntensiveObjects(
    reportObjects,
    sortedDetailedReport
  );
  const topOrganizations = useTopResourceIntensiveOrganizations(
    reportObjects,
    reportOrganizations,
    sortedDetailedReport
  );
  const detailedEgress = useDetailedReportData(
    parameters,
    reportObjects,
    reportOrganizations,
    topObjects.topEgress,
    topOrganizations.topEgress,
    sortedDetailedReport
  );
  const detailedStorage = useDetailedReportData(
    parameters,
    reportObjects,
    reportOrganizations,
    topObjects.topStorage,
    topOrganizations.topStorage,
    sortedDetailedReport
  );
  const summaryEgress = useSummaryReportData(
    parameters,
    reportObjects,
    reportOrganizations,
    topObjects.topEgress,
    topOrganizations.topEgress,
    sortedTotalReport
  );
  const summaryStorage = useSummaryReportData(
    parameters,
    reportObjects,
    reportOrganizations,
    topObjects.topStorage,
    topOrganizations.topStorage,
    sortedTotalReport
  );
  const totalSize = useReportTotalSize(reportObjects, sortedTotalReport);
  const totalReportEntities = useTotalReportEntities(
    reportObjects,
    sortedTotalReport
  );
  const barColors = useBarColors(
    parameters,
    isEgress,
    topObjects,
    topOrganizations
  );
  const labels = useChartLabels(parameters);

  useEffect(() => {
    onParametersUpdate(parameters.dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParameters({
      ...parameters,
      isGroupedByOrganization: initialState.isGroupedByOrganization,
      selectedReportObjects: initialState.selectedReportObjects
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const bigChartWidth =
    containerWidth && smallChartWidth
      ? containerWidth - 40 - smallChartWidth
      : 0;
  const reportGranularity =
    horizontalTableLabel[parameters.dateRangeType.value as DateRangeType];
  const hasReport =
    detailedReport &&
    totalReport &&
    availableReportObjects &&
    (parameters.selectedReportObjects.length ||
      parameters.reportObject?.value === 'all');

  const detailedEgressDatasets = useTransformDetailedDatasets(
    'egress',
    detailedEgress.reportData
  );
  const detailedStorageDatasets = useTransformDetailedDatasets(
    'storage',
    detailedStorage.reportData
  );
  const summaryEgressDatasets = useTransformSummaryDatasets(summaryEgress);
  const summaryStorageDatasets = useTransformSummaryDatasets(summaryStorage);

  const onUpdate = (newParameters: ReportParametersModel) => {
    setParameters(newParameters);
    if (
      newParameters.dateRange.startDate !== parameters.dateRange.startDate ||
      newParameters.dateRange.endDate !== parameters.dateRange.endDate
    ) {
      onParametersUpdate(newParameters.dateRange);
    }
  };

  const getDetailedBarParams = useCallback(
    (isStacked, datasetsAmount) =>
      getBarParams(isStacked, datasetsAmount, reportGranularity),
    [reportGranularity]
  );

  return (
    <VStack h="100%" w="100%" spacing="40px" ref={containerRef}>
      <VStack w="100%" spacing="16px">
        <HStack align="stretch" spacing="40px" w="100%" h="100%" pt="16px">
          <VStack align="start" w="100%" spacing="24px">
            <VStack
              spacing="24px"
              w="100%"
              align="start"
              divider={<StackDivider borderColor="border.divider" />}
            >
              <ReportParameters
                isLoading={isLoading}
                showOrganizationDetails={showOrganizationDetails}
                totalReport={totalReport}
                parameters={parameters}
                noOptionsLabel={noOptionsLabel}
                reportObjects={sortedAvailableReportObjects}
                onParametersUpdate={onUpdate}
              >
                {children}
              </ReportParameters>
              <ReportSummary
                totalSize={totalSize}
                report={sortedDetailedReport}
                reportObjects={sortedAvailableReportObjects}
                parameters={parameters}
              />
            </VStack>
            {hasReport ? (
              <HStack spacing="40px" align="start" w="100%">
                <Box ref={smallChartRef}>
                  <BarChart
                    name="summary"
                    getBarParams={getSummaryBarParams}
                    containerProps={summaryContainerProps}
                    isStacked
                    labels={summaryLabels}
                    datasets={
                      isEgress ? summaryEgressDatasets : summaryStorageDatasets
                    }
                    barColors={barColors}
                    verticalLabel="Current size (Gb)"
                    w={145}
                    h={400}
                    renderTooltip={
                      parameters.isGroupedByOrganization
                        ? renderSummaryGroupedTooltip(
                            isEgress ? summaryEgress : summaryStorage
                          )
                        : renderSummaryTooltip(
                            showOrganizationDetails,
                            isEgress ? summaryEgress : summaryStorage
                          )
                    }
                  />
                </Box>
                <Tabs
                  variant="unstyled"
                  h="100%"
                  w="100%"
                  bg="background.primary"
                  d="flex"
                  flexDirection="column"
                  pos="relative"
                  alignItems="center"
                  index={tabIndex}
                >
                  <Flex w="100%" justify="center" pos="relative">
                    <TabList pos="relative" maxW="1128px" w="100%" h="48px">
                      <Flex
                        h="100%"
                        w="100%"
                        justify="space-between"
                        align="center"
                      >
                        <HStack spacing="24px" h="100%">
                          <CustomTab
                            h="100%"
                            onClick={() => setTabIndex(0)}
                            renderButtonContent={(isActive) => (
                              <Tab isActive={isActive}>Egress data traffic</Tab>
                            )}
                          />
                          <CustomTab
                            h="100%"
                            onClick={() => setTabIndex(1)}
                            renderButtonContent={(isActive) => (
                              <Tab isActive={isActive}>Storage usage</Tab>
                            )}
                          />
                        </HStack>
                        <ChartParameters
                          organizationId={organizationId}
                          parameters={parameters}
                          onUpdate={onUpdate}
                        />
                      </Flex>
                    </TabList>
                  </Flex>
                  <TabPanels
                    pt="24px"
                    px="0"
                    h="100%"
                    d="flex"
                    flexDir="column"
                    overflowY="hidden"
                    alignItems="center"
                  >
                    <TabPanel
                      {...tabPanelStyles}
                      data-testid="egressDataTrafficTab"
                    >
                      <BarChart
                        name="egressDetails"
                        getBarParams={getDetailedBarParams}
                        isStacked={parameters.isStacked}
                        labels={labels}
                        datasets={detailedEgressDatasets}
                        barColors={barColors}
                        horizontalLabel={reportGranularity}
                        verticalLabel={dimensionLabels[Dimension.Traffic]}
                        w={bigChartWidth}
                        h={400}
                        renderTooltip={
                          parameters.isGroupedByOrganization
                            ? renderDetailedGroupedTooltip(
                                reportGranularity,
                                Dimension.Traffic,
                                labels,
                                detailedEgress.reportData
                              )
                            : renderDetailedTooltip(
                                reportGranularity,
                                showOrganizationDetails,
                                Dimension.Traffic,
                                labels,
                                detailedEgress.reportData
                              )
                        }
                      />
                    </TabPanel>
                    <TabPanel {...tabPanelStyles} data-testid="storageUsageTab">
                      <BarChart
                        name="storageDetails"
                        getBarParams={getDetailedBarParams}
                        isStacked={parameters.isStacked}
                        labels={labels}
                        datasets={detailedStorageDatasets}
                        barColors={barColors}
                        horizontalLabel={reportGranularity}
                        verticalLabel={dimensionLabels[Dimension.Storage]}
                        w={bigChartWidth}
                        h={400}
                        renderTooltip={
                          parameters.isGroupedByOrganization
                            ? renderDetailedGroupedTooltip(
                                reportGranularity,
                                Dimension.Storage,
                                labels,
                                detailedStorage.reportData
                              )
                            : renderDetailedTooltip(
                                reportGranularity,
                                showOrganizationDetails,
                                Dimension.Storage,
                                labels,
                                detailedStorage.reportData
                              )
                        }
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </HStack>
            ) : (
              <ReportStatus
                entity={
                  organizationId
                    ? !parameters.selectedReportObjects.length
                      ? parameters.reportObject?.value || ''
                      : ''
                    : 'organization'
                }
                isError={isError}
                isLoading={isLoading}
              />
            )}
          </VStack>
        </HStack>
      </VStack>
      {hasReport ? (
        <ReportTable
          barColors={barColors}
          showOrganizationDetails={showOrganizationDetails}
          data={isEgress ? detailedEgress.tableData : detailedStorage.tableData}
          totalReportEntities={totalReportEntities}
          isLoading={isLoading}
          isError={isError}
          parameters={parameters}
        />
      ) : (
        <Box h="40px" />
      )}
    </VStack>
  );
};
