import { Box, Tooltip } from '@chakra-ui/react';
import { ErrorIcon, WarningIcon } from './assets';
import { useParams } from 'react-router-dom';
import { PanelFormData, useFeatureFlagsStore } from 'store';
import { useListFeatureTogglesQuery } from 'api/hooks';
import { MERFISH1_FEATURE_FLAG } from '@vizgen/vizgen-ui';
import { parseFeatureFlagValue } from 'utils';
import { STANDARD_MERFISH20_GENES_AMOUNT } from 'consts';
import pluralize from 'pluralize';

export interface ChemistryValidation {
  isLocked: boolean;
  isSubmitDisabled: boolean;
  icon: JSX.Element | null;
}

const renderTooltip = (hasError: boolean, icon: JSX.Element, text: string) =>
  hasError ? (
    <Tooltip label={text}>
      <Box w="24px">{icon}</Box>
    </Tooltip>
  ) : null;

export function useChemistryValidation({
  sourcePanel,
  merfishVersion
}: PanelFormData): ChemistryValidation {
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);
  const { panelId } = useParams<{ panelId: string }>();
  const { data = [] } = useListFeatureTogglesQuery({
    name: MERFISH1_FEATURE_FLAG
  });
  const isMerfish1Forbidden = !parseFeatureFlagValue(data, true);

  if (!gm201 || !merfishVersion) {
    return {
      isLocked: false,
      isSubmitDisabled: false,
      icon: null
    };
  }

  if (panelId) {
    const hasError = isMerfish1Forbidden && merfishVersion?.value === '1';

    return {
      isLocked: false,
      isSubmitDisabled: hasError,
      icon: renderTooltip(
        hasError,
        <ErrorIcon data-testid="errorIcon" />,
        'Creation of MERFISH 1.0 panels is disabled. Please toggle the panel to MERFISH 2.0 to continue the design.'
      )
    };
  } else {
    if (sourcePanel?.panelId === 'custom') {
      return {
        isLocked: isMerfish1Forbidden,
        isSubmitDisabled: false,
        icon: null
      };
    } else {
      const genes = sourcePanel?.genesSummary?.primary || 0;
      const sourcePanelMerfishVersion = sourcePanel?.merfishVersion ?? '1';
      const hasWarning = sourcePanelMerfishVersion !== merfishVersion.value;
      const withSpikeInKits =
        sourcePanelMerfishVersion === '1' &&
        merfishVersion.value === '2' &&
        genes > STANDARD_MERFISH20_GENES_AMOUNT;

      return {
        isLocked: isMerfish1Forbidden,
        isSubmitDisabled: false,
        icon: renderTooltip(
          hasWarning,
          <WarningIcon data-testid="warningIcon" />,
          withSpikeInKits
            ? `The base panel is MERFISH 1.0 and contains ${genes} ${pluralize(
                'gene',
                genes
              )}. The new panel will be designed for MERFISH 2.0 chemistry. This will preclude the use of spike-in kits.`
            : `The base panel is MERFISH ${sourcePanelMerfishVersion}.0, but the new panel will be designed for MERFISH ${merfishVersion.value}.0 chemistry.`
        )
      };
    }
  }
}
