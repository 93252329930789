import {
  Flex,
  Popover,
  PopoverTrigger,
  ListItem,
  Stack,
  HStack,
  Text,
  Box,
  Button,
  Portal,
  IconButton,
  PopoverContent,
  PopoverHeader,
  PopoverBody
} from '@chakra-ui/react';
import { FC, CSSProperties, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import {
  PopoverCloseIcon,
  PopoverInfoIcon,
  PopoverInfoTriggeredIcon
} from 'assets';
import { SequenceSummary } from './components';
import { useCreatePanelStore } from 'store';
import { useParams } from 'react-router';
import { ExogenousSequence } from 'generated/types';

interface SequenceItemProps {
  sequence: ExogenousSequence;
  style: CSSProperties;
}

export const SequenceItem: FC<SequenceItemProps> = ({ sequence, style }) => {
  const { panelId } = useParams();
  const isSequenceUpdating = useCreatePanelStore(
    (state) => state.isSequenceUpdating
  );
  const addExogenousSequenceToPanel = useCreatePanelStore(
    (state) => state.addExogenousSequenceToPanel
  );
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const { ref } = useResizeDetector();

  const addSequence = async () => {
    await addExogenousSequenceToPanel(sequence.name, sequence.id, panelId!);
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={close}>
      <PopoverTrigger>
        <ListItem
          style={style}
          h="64px"
          d="flex"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="border.divider"
          p="0 8px 3px 12px"
          css={{ '&:last-child': { borderBottom: 0 } }}
          ref={ref}
        >
          <Stack spacing="0" mt="8px">
            <HStack mb="4px" alignItems="center" spacing="4px">
              <Text variant="accentBodyShort">{sequence.name}</Text>
              <Box as="span" onClick={open}>
                {isOpen ? (
                  <PopoverInfoTriggeredIcon />
                ) : (
                  <PopoverInfoIcon cursor="pointer" />
                )}
              </Box>
            </HStack>
            <Text>
              {sequence.fpkm}{' '}
              <Text as="span" color="content.additional">
                abundance
              </Text>
            </Text>
          </Stack>
          <Button
            mt="4px"
            size="tiny"
            variant="tinyGhostPrimary"
            onClick={addSequence}
            disabled={isSequenceUpdating}
          >
            Add
          </Button>
        </ListItem>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          pos="relative"
          mt="-8px"
          w={(ref.current as any)?.getBoundingClientRect().width}
          border="1px solid"
          borderColor="border.normal"
          boxShadow="0px 4px 8px rgba(215, 217, 220, 0.4)"
        >
          <PopoverHeader
            p="8px 12px"
            fontWeight="heavy"
            border="0"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center">
              <Text variant="accentBodyShort">{sequence.name}</Text>
            </Flex>
            <IconButton
              aria-label="Close"
              variant="primaryGhost"
              size="auto"
              icon={<PopoverCloseIcon />}
              onClick={close}
            />
          </PopoverHeader>
          <PopoverBody p="8px 12px">
            <SequenceSummary sequence={sequence} />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
