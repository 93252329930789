import { CostReportModel, Granularity } from './types';
import { BarParams } from './../BarChart';
import { DateRangeType, ReportDateRange } from './types';
import dayjs from 'dayjs';
import { OTHER_ENTITIES, TOP_OBJECTS_AMOUNT } from './consts';
import { bytesToGigabytes } from '../../utils/bytesToGigabytes';

export function updateCostReportData(
  data: CostReportModel[],
  r: CostReportModel
): CostReportModel[] {
  const dataMap = new Map(data.map((item) => [item.objectUuid, item]));

  const existingItem = dataMap.get(r.objectUuid);
  if (existingItem) {
    existingItem.currentSize += r.currentSize;
    existingItem.storage += r.storage;
    existingItem.egress += r.egress;
  } else {
    dataMap.set(r.objectUuid, r);
  }

  return Array.from(dataMap.values());
}

export function formatChartValue(value?: number | null): number | null {
  const gb = value || 0;

  return Number(gb.toFixed(6)) || null;
}

export function formatOutputValue(value?: number | null): number | string {
  if (!value) {
    return 0;
  }

  const maximumFractionDigits = value > 1 ? 2 : 6;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 1,
    maximumFractionDigits
  });

  const formatted = formatter.format(value);

  return formatted === '0.0' ? 0 : formatted;
}

export function getBarParams(
  isStacked: boolean,
  datasetsAmount: number,
  granularity: Granularity
): BarParams {
  if (isStacked) {
    if (granularity === 'Days') {
      return {
        barThickness: 16,
        borderWidth: 0,
        barWidth: 16,
        offset: 0,
        spacing: 2
      };
    }
    return {
      barThickness: 56,
      borderWidth: 0,
      barWidth: 56,
      offset: 0,
      spacing: 16
    };
  } else {
    return {
      barThickness: 12,
      borderWidth: 1,
      barWidth: datasetsAmount * 12,
      offset: 2,
      spacing: 32
    };
  }
}

const DATE_FORMAT = 'YYYY-MM-DD';
const builder: Record<DateRangeType, () => ReportDateRange> = {
  [DateRangeType.CurrentMonth]: () => {
    const endDate = dayjs();
    const startDate = endDate.startOf('month').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.format(DATE_FORMAT)
    };
  },
  [DateRangeType.CurrentYear]: () => {
    const endDate = dayjs();
    const startDate = endDate.startOf('year').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.format(DATE_FORMAT)
    };
  },
  [DateRangeType.Previous3Months]: () => {
    const endDate = dayjs().startOf('month');
    const startDate = endDate.subtract(3, 'month').format(DATE_FORMAT);

    return {
      startDate,
      endDate: endDate.subtract(1, 'day').format(DATE_FORMAT)
    };
  },
  [DateRangeType.PreviousMonth]: () => {
    const previousMonth = dayjs().subtract(1, 'month');
    const startDate = previousMonth.startOf('month').format(DATE_FORMAT);
    const endDate = previousMonth.endOf('month').format(DATE_FORMAT);

    return {
      startDate,
      endDate
    };
  }
};

export function getDateRange(dateRange: DateRangeType): ReportDateRange {
  const exec = builder[dateRange];
  if (exec) {
    return exec();
  } else {
    throw new Error(`Unknown date range: ${dateRange}`);
  }
}

export function getMonthArray() {
  const months = [];
  for (let i = 0; i < 12; i++) {
    const month = dayjs().month(i).format('MMM');
    const capitalizedMonth =
      month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    months.push(capitalizedMonth);
  }
  return months;
}

export function getDaysArray(startDate: string, endDate: string): string[] {
  const dates = [];
  let currentDate = dayjs(startDate);

  while (
    currentDate.isBefore(dayjs(endDate)) ||
    currentDate.isSame(dayjs(endDate))
  ) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
}

interface TopEntity {
  uuid: string;
  egress: bigint;
  bytesHours: bigint;
}

export function getTopEntities(
  entities: TopEntity[],
  key: 'bytesHours' | 'egress'
) {
  const top = entities.sort(
    (a, b) => bytesToGigabytes(b[key]) - bytesToGigabytes(a[key])
  );

  const topSet = new Set(top.slice(0, TOP_OBJECTS_AMOUNT).map((r) => r.uuid));

  if (top.length > TOP_OBJECTS_AMOUNT) {
    topSet.add(OTHER_ENTITIES);
  }

  return topSet;
}
